import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <>
      <div className="footer-container">
        <div className="footer-copyright">
          Copyright &copy; 2024 by ProKardio. Wszelkie prawa zastrzeżone.
        </div>

        <div className="footer-author">
          Generała Mariana Langiewicza 37,
          <br /> 65-740 Zielona Góra
          <br />
          +48 68 325 0898
        </div>
      </div>
    </>
  );
}

export default Footer;
