import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './Components/Navbar';
import Home from './Components/pages/MainPage/Home.js';
import Footer from './Components/Footer'
import Contact from './Components/pages/Contact/Contact';
import Registration from './Components/pages/MainPage/Registration';
import Offer from './Components/pages/Offer/Offer';




function App() {
  return (
   <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/oferta" element={<Offer/>} />
          {/* <Route path="/cennik" element={<PriceList/>} /> */}
          <Route path="/kontakt" element={<Contact/>} />
          <Route path="/rejestracja" element={<Registration/>} />
        </Routes>
      <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
