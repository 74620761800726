import React from 'react'
import './DoctorsBar.css'

import DoctorCard from './DoctorCard'
import Data from '../../doctors.json'


function DoctorsBar() {
  return (
    <>
    <div className='doctorsbar-container'>

      { Data.map(post =>{
        return(
          <div key={post.id} className='cards'>
           <DoctorCard image={post.image} name={post.name} specialization={post.specialization} path={post.path}/>
          </div>
         
        )
      } )
      
      }
    </div>
    
    </>
  )
}

export default DoctorsBar