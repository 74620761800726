import React from 'react'

import './DoctorCard.css'


function DoctorCard(props) {
  return (
    <>
    <div className='card-container'>
      <a href={props.path} className='card-link' target="_blank" rel="noreferrer">
        <img src={props.image} alt="doctorPhoto" className='card-photo'>
          </img>

          <div className='card-description'>
          <h1>{props.name}</h1>
          <img src='/smallLogoZnanyLekarz.png' alt="" className='znany-lekarz-small-logo'/>
          <p>{props.specialization}</p>
         
          </div>
           
      </a>
    </div>
    </>
  )
}

export default DoctorCard