import React from "react";
import "./Registration.css";

function Registration() {
  return (
    <>
      <div className="registration-container">
        <i className="fa-solid fa-calendar-days"></i>
        <h1>Umów się na wizytę</h1>
        <p>
          Zadzwoń do nas pod &nbsp;
          <a href="tel:+48 68 325 0898">tel: +48 68 325 0898</a> lub kliknij
          przycisk poniżej i umów się na wizytę online!
        </p>

        <a
          href="https://www.znanylekarz.pl/placowki/prokardio"
          className="registration-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="registration-button">Umów się</button>
        </a>
      </div>
    </>
  );
}

export default Registration;
