import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import './Navbar.css'
function Navbar() {
  
  const [click, setClick] = useState(false)
  const [image, setImage] = useState(true);


  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showImage = () => {
      if(window.innerWidth >= 960){
            setImage(true);
            setClick(false);
      }else{
            setImage(false);
      }
  }  

  useEffect(() => {
    showImage();
  },[]);

  window.addEventListener('resize', showImage)

  


  
  return (
   <>
    <nav className='navbar'>
     <div className='navbar-container'>
        <Link to="/" className="navbar-logo" onClick ={closeMobileMenu}>
          <img src="/logo_brak_taglinu.png" alt="logo" className='navbar-logo-img'/>
          <p className='navbar-logo-text'>poradnia specjalistyczna</p>
        </Link>
        
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>

        <ul className={click ? 'navbar-menu active' : 'navbar-menu'}>
          <li className='navbar-item'>
              <Link to="/" className='nav-link' onClick ={closeMobileMenu}>
                  Strona główna
              </Link>
          </li>
          
          <li className='navbar-item'>
              <Link to="/oferta" className='nav-link' onClick ={closeMobileMenu}>
                  Nasze poradnie
              </Link>
          </li>
          {/* <li className='navbar-item'>
              <Link to="/cennik" className='nav-link' onClick ={closeMobileMenu}>
                  Cennik
              </Link>
          </li> */}
          <li className='navbar-item'>
              <Link to="/kontakt" className='nav-link' onClick ={closeMobileMenu}>
                  Kontakt
              </Link>
          </li>
          <li className='navbar-item'>
              <Link to="/rejestracja" className='nav-link' onClick ={closeMobileMenu}>
                  Rejestracja
              </Link>
          </li>
         

        </ul>
        {image && <a href="https://www.znanylekarz.pl/placowki/prokardio" 
        className='znanylekarz' target="_blank" rel="noopener noreferrer">
            <img src="/znanylekarz.png" alt="znanylekarz" className='znanylekarz-img'/>
        </a>}
     </div>
   </nav>
   </>

  )
}

export default Navbar;