import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <>
      <div className="contact-container">
        <div className="left">
          <div className="contact-box">
            <h1>Dane kontaktowe:</h1>
            <p>
              <a href="tel:+48 68 325 0898">tel: +48 68 325 0898</a>
              <br />
              e-mail: rejestracja@prokardio.pl
              <br />
              Generała Mariana Langiewicza 37
              <br />
              65-740 Zielona Góra
            </p>
            <div className="map-button">
              <a href="https://goo.gl/maps/eCEcwGneBtF2gEnz7">
                <p>Otwórz mapę</p>
              </a>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1229.5787834300977!2d15.496621702857855!3d51.94931806726346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470613eef38e2845%3A0xc4e6005cf4c2f368!2sGenera%C5%82a%20Mariana%20Langiewicza%2037%2C%2065-740%20Zielona%20G%C3%B3ra!5e0!3m2!1spl!2spl!4v1729857365200!5m2!1spl!2spl"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
            className="map-container"
          ></iframe>
        </div>
        {/* <div className="right">
          <img src="./prokardiowejscie.jpg" alt="wejscie" />
        </div> */}
      </div>
    </>
  );
}

export default Contact;
