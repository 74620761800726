import React from 'react'
import './OfferCard.css'

function OfferCard(props) {
  return (
    <>
      <div className='offer-card-container'>
        <div className='photo-container'>
          <img src={props.src} alt='offer-img' className='offer-photo' />
          </div>
        <div className='offer-card-text-container' >
          <h1>
                {props.type}
          </h1>
          <p>
              {props.description}:
         
          <br />
              - {props.treated1}
              <br />
              - {props.treated2}
              <br />
              - {props.treated3}
              <br />
              - {props.treated4}
              </p>
          
         
        </div>
      </div>
    </>
  )
}

export default OfferCard