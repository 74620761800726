import React from 'react'

import HeroSection from './HeroSection'
import DoctorsBar from './DoctorsBar'
import Registration from './Registration'


//import '../../../App.css'



function Home() {
  return (
    <>
    <HeroSection />
    <DoctorsBar />
   
    <Registration />
    </>
  );
}

export default Home